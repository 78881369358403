export const useCta = () => {
  const { useItems } = useCms();
  const { readItems } = useItems();

  const getCtaContent = async (page: MaybeRef<PageType>) => {
    return await readItems("cta", {
      limit: 1,
      offset: 0,
      filter: { page: { _eq: unref(page) } },
    })
      .then((response: CtaContentItem[]) => response?.[0] || null)
      .catch(() => null);
  };

  return { getCtaContent };
};
