<script setup lang="ts">
const { currentPalette } = useTheme();
const { striptags, nl2br } = useHelper();

const slots = useSlots();

const props = withDefaults(
  defineProps<{
    preTitle?: string;

    title?: {
      prepend?: string;
      default?: string;
      append?: string;
    };

    content: string[];
  }>(),
  {
    preTitle: undefined,

    title: () => ({
      prepend: undefined,
      default: undefined,
      append: undefined,
    }),

    content: () => [],
  }
);

const hasPreTitle = computed(() => props.preTitle || slots["pre-title"]);

const hasPrepend = computed(() => props.title?.prepend);
const hasDefault = computed(() => props.title?.default);
const hasAppend = computed(() => props.title?.append);

const hasTitle = computed(
  () => hasPrepend.value || hasDefault.value || hasAppend.value || slots.title
);

const hasPrependAndDefault = computed(
  () => hasPrepend.value && hasDefault.value
);

const hasDefaultAndAppend = computed(() => hasDefault.value && hasAppend.value);

const hasContent = computed(() => props.content.length > 0 || slots.default);
</script>

<template>
  <section
    v-if="hasPreTitle || hasTitle || hasContent"
    class="app-footer-before pt-12 pb-[5.25rem] contrast-fix"
    :class="[currentPalette.bg]"
  >
    <div class="app-footer-before__container container">
      <div
        class="app-footer-before__wrapper lg:px-40 flex flex-col items-stretch gap-4 lg:gap-6"
      >
        <div
          class="app-footer-before__title flex flex-col items-stretch gap-4 lg:gap-6"
        >
          <p
            v-if="hasPreTitle"
            class="text-sm leading-6 font-medium lg:font-normal lg:leading-4"
          >
            <slot name="pre-title" v-bind="{ props }">
              {{ props.preTitle }}
            </slot>
          </p>

          <h2
            v-if="hasTitle"
            class="text-white text-[2.5rem] leading-[3.75rem] lg:text-[4.375rem] lg:leading-[3.3125rem] contrast-fix"
          >
            <slot name="title" v-bind="{ props }">
              <span v-if="hasPrepend" class="tracking-[0.25rem] font-display">
                {{ props.title.prepend }}
              </span>
              <span v-if="hasPrependAndDefault" class="tracking-[0.25rem]"
                >&emsp14;</span
              >
              <span v-if="hasDefault">
                {{ props.title.default }}
              </span>
              <span v-if="hasDefaultAndAppend" class="tracking-[0.25rem]"
                >&emsp14;</span
              >
              <span v-if="hasAppend" class="tracking-[0.25rem] font-display">
                {{ props.title.append }}
              </span>
            </slot>
          </h2>
        </div>

        <div
          v-if="hasContent"
          class="app-footer-before__content flex flex-col items-stretch gap-6 text-sm leading-6 font-light lg:font-normal"
        >
          <slot v-bind="{ props }">
            <p
              v-for="paragraph in props.content"
              v-html="nl2br(striptags(paragraph))"
            ></p>
          </slot>
        </div>
      </div>
    </div>
  </section>
</template>
