export const usePostListing = () => {
  const { postFieldPresets } = usePosts();
  const { tags } = usePostFilter();

  const { getPosts, defaultPosts } = usePosts();

  const loadPosts = async (
    options: {
      fields?: MaybeRef<string[] | undefined>;
      type?: MaybeRef<PostType | undefined>;
      page?: MaybeRef<number | undefined>;
      limit?: MaybeRef<number | undefined>;
      filter?: MaybeRef<Record<PostType, string[]> | undefined>;
      bounds?: MaybeRef<google.maps.LatLngBoundsLiteral | undefined>;
    } = {}
  ) => {
    const fields = unref(options.fields) || postFieldPresets.listing;
    const type = unref(options.type) || undefined;
    const page = unref(options.page) || 1;
    const limit = unref(options.limit) || 20;
    const filter = unref(options.filter) || {};
    const bounds = unref(options.bounds) || undefined;

    const filterValue: { _or: Record<string, unknown>[] } = { _or: [] };

    const geometryFilter = bounds
      ? {
          _and: [
            { location_map_enabled: { _eq: true } },
            {
              location_map_marker: {
                _intersects_bbox: {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [bounds.west, bounds.north],
                        [bounds.west, bounds.south],
                        [bounds.east, bounds.south],
                        [bounds.east, bounds.north],
                        [bounds.west, bounds.north],
                      ],
                    ],
                  },
                },
              },
            },
          ],
        }
      : undefined;

    for (const [key, value] of Object.entries<string[]>(filter) as [
      PostType,
      string[]
    ][]) {
      if (value.includes("OTHER")) {
        filterValue._or.push({
          _and: [
            { type: { _eq: key } },
            {
              _or: [
                {
                  tags: {
                    tags_id: {
                      title: {
                        _nin: tags.value[key].map((item: any) => item.title!),
                      },
                    },
                  },
                },
                { tags: { tags_id: { title: { _null: true } } } },
              ],
            },
            ...(geometryFilter ? [geometryFilter] : []),
          ],
        });
      } else if (value.length > 0) {
        filterValue._or.push({
          _and: [
            { type: { _eq: key } },
            { tags: { tags_id: { title: { _in: value } } } },
            ...(geometryFilter ? [geometryFilter] : []),
          ],
        });
      } else {
        filterValue._or.push({
          _and: [
            { type: { _eq: key } },
            ...(geometryFilter ? [geometryFilter] : []),
          ],
        });
      }
    }

    return await getPosts({
      fields: fields,
      type: type,
      filter: filterValue,
      page: page,
      limit: limit,
      sort: "-date_published",
    })
      .then((response) => response)
      .catch(() => defaultPosts);
  };

  return { loadPosts };
};
