export const usePageSlider = () => {
  const { useItems } = useCms();
  const { readItems } = useItems();

  const getSlides = async (page?: MaybeRef<PageType>) => {
    page = unref(page);

    return await readItems("slider", {
      limit: -1,
      offset: 0,
      fields: ["*", "image.*"],
    }).then((response) => {
      return page
        ? response?.filter((item: any) => item.pages?.includes(page))
        : response;
    });
  };

  return { getSlides };
};
