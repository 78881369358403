<script setup lang="ts">
const { currentPalette } = useTheme();
const { striptags, nl2br } = useHelper();

const emit = defineEmits(["click:action"]);

const slots = useSlots();

const props = withDefaults(
  defineProps<{
    preTitle?: string;

    title?: {
      prepend?: string;
      default?: string;
      append?: string;
    };

    content: string[];

    cta?: {
      prompt?: string;
      button?: string;
    };

    container?: boolean;
  }>(),
  {
    preTitle: undefined,

    title: () => ({
      prepend: undefined,
      default: undefined,
      append: undefined,
    }),

    content: () => [],

    cta: () => ({
      prompt: undefined,
      button: undefined,
    }),
  }
);

const hasPreTitle = computed(() => props.preTitle || slots["pre-title"]);

const hasPrepend = computed(() => props.title?.prepend);
const hasDefault = computed(() => props.title?.default);
const hasAppend = computed(() => props.title?.append);

const hasTitle = computed(
  () => hasPrepend.value || hasDefault.value || hasAppend.value || slots.title
);

const hasPrependAndDefault = computed(
  () => hasPrepend.value && hasDefault.value
);

const hasDefaultAndAppend = computed(() => hasDefault.value && hasAppend.value);

const hasContent = computed(() => props.content.length > 0 || slots.default);

const hasPrompt = computed(() => props.cta?.prompt);
const hasButton = computed(() => props.cta?.button);

const hasCta = computed(() => hasPrompt.value || hasButton.value);
</script>

<template>
  <section class="post-grid-cta -mb-[6.25rem]">
    <div
      class="post-grid-cta__container"
      :class="{ 'lg:container': container }"
    >
      <div
        class="post-grid-cta__wrapper px-4 pt-10 pb-[8.75rem] flex flex-col items-stretch gap-4 lg:gap-6 contrast-fix lg:px-40"
        :class="[currentPalette.bg]"
      >
        <div
          class="post-grid-cta__title flex flex-col items-stretch gap-4 lg:gap-6"
        >
          <p
            v-if="hasPreTitle"
            class="font-medium text-sm leading-6 lg:text-base lg:leading-7"
          >
            <slot name="pre-title" v-bind="{ props }">
              {{ props.preTitle }}
            </slot>
          </p>

          <h2
            v-if="hasTitle"
            class="text-white tracking-[0.25rem] text-[2.5rem] leading-[2.5rem] lg:text-[4.375rem] lg:leading-[3.3125rem]"
          >
            <slot name="title" v-bind="{ props }">
              <span v-if="hasPrepend" class="tracking-[0.25rem] font-display">
                {{ props.title.prepend }}
              </span>
              <span v-if="hasPrependAndDefault" class="tracking-[0.25rem]"
                >&emsp14;</span
              >
              <span v-if="hasDefault">
                {{ props.title.default }}
              </span>
              <span v-if="hasDefaultAndAppend" class="tracking-[0.25rem]"
                >&emsp14;</span
              >
              <span v-if="hasAppend" class="tracking-[0.25rem] font-display">
                {{ props.title.append }}
              </span>
            </slot>
          </h2>
        </div>

        <div
          v-if="hasContent"
          class="post-grid-cta__content font-light text-sm leading-6 lg:font-medium lg:text-base lg:leading-7"
        >
          <slot v-bind="{ props }">
            <p
              v-for="paragraph in props.content"
              v-html="nl2br(striptags(paragraph))"
            ></p>
          </slot>
        </div>

        <div
          v-if="hasCta"
          class="post-grid-cta__action flex flex-col lg:flex-row items-stretch lg:items-center justify-end gap-4 lg:gap-12"
        >
          <div
            v-if="hasPrompt"
            class="post-grid-cta__action-prompt text-[1.625rem] lg:text-[2.5rem] leading-[2.75rem] font-medium italic text-white"
          >
            <p>{{ props.cta.prompt }}</p>
          </div>

          <div
            v-if="hasButton"
            class="post-grid-cta__action-button flex items-center justify-center"
          >
            <AppBtn
              variant="outline"
              size="md"
              type="button"
              @click="emit('click:action')"
            >
              <span>{{ props.cta.button }}</span>
            </AppBtn>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
