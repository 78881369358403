<script setup lang="ts">
defineProps<{ categories?: PostType[] }>();

const { filter, tags } = usePostFilter();

const resetFilter = () => {
  filter.value = {
    inspiration: [],
    architect: [],
    professional: [],
    relaxation: [],
    contractor: [],
    project: [],
  };
};

const count = computed(() =>
  Object.values(filter.value).reduce((acc, tags) => acc + tags.length, 0)
);

const isOpen = ref(false);
</script>

<template>
  <PostFilterButton
    v-bind="$attrs"
    v-on:click="isOpen = true"
    v-bind:count="count"
  ></PostFilterButton>

  <PostFilterDialog
    v-model:is-open="isOpen"
    v-model="filter"
    v-bind="{ tags, categories }"
    v-on:reset="resetFilter"
  ></PostFilterDialog>
</template>
