<script setup lang="ts">
const props = withDefaults(
  defineProps<{ title: string; titleTag?: string | Component }>(),
  { titleTag: "h3" }
);
</script>

<template>
  <div class="post-filter-category">
    <div
      class="post-filter-category__wrapper flex flex-col items-stretch gap-2"
    >
      <div
        v-if="props.title || $slots.title"
        class="post-filter-category__title"
      >
        <component
          :is="props.titleTag"
          class="text-base leading-7 font-semibold"
        >
          <slot name="title">
            {{ props.title }}
          </slot>
        </component>
      </div>

      <div class="post-filter-category__content">
        <div
          class="post-filter-category__content-wrapper flex items-center justify-start gap-2 flex-wrap"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
