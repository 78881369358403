export const usePostTags = () => {
  const { useItems } = useCms();
  const { readItems } = useItems();

  const getFilterTags = (type?: MaybeRef<PostType>) => {
    type = unref(type);

    return readItems("tags", {
      filter: {
        filters: type ? { _contains: type } : { _nnull: true },
      },
    });
  };

  return { getFilterTags };
};
