<script setup lang="ts">
const emit = defineEmits<{ "update:modelValue": [value: boolean] }>();

const props = withDefaults(defineProps<{ modelValue?: boolean }>(), {});

const model = useVModel(props, "modelValue", emit, {
  defaultValue: false,
});
</script>

<template>
  <button
    :class="[
      'post-filter-checkbox',
      'flex items-center justify-center gap-2',

      'py-2 px-4',
      'h-8 w-auto min-w-0',

      'whitespace-nowrap text-ellipsis overflow-hidden',

      'rounded-e-2xl rounded-b-2xl',

      'text-center',
      'text-xs leading-[0.875rem]',
      'font-normal',

      'border-[0.0625rem] border-transparent',
      'hover:border-black focus-visible:border-black',

      model ? 'bg-black text-white' : 'bg-grey-50 text-grey-500',
    ]"
    @click="model = !model"
    title="Filtruj"
    v-bind="$attrs"
  >
    <slot></slot>
    <Icon v-if="model" name="ns:close-small" class="w-4 h-4" />
  </button>
</template>
