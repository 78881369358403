export const usePostFilter = () => {
  const route = useRoute();
  const router = useRouter();

  const queryDefault = "";
  const queryOptions = { route, router };

  const inspirations = useRouteQuery<string>(
    "filter[inspiration]",
    queryDefault,
    queryOptions
  );

  const architects = useRouteQuery<string>(
    "filter[architect]",
    queryDefault,
    queryOptions
  );

  const professionals = useRouteQuery<string>(
    "filter[professional]",
    queryDefault,
    queryOptions
  );

  const relaxation = useRouteQuery<string>(
    "filter[relaxation]",
    queryDefault,
    queryOptions
  );

  const contractors = useRouteQuery<string>(
    "filter[contractor]",
    queryDefault,
    queryOptions
  );

  const projects = useRouteQuery<string>(
    "filter[project]",
    queryDefault,
    queryOptions
  );

  const filter = computed({
    get: () => ({
      inspiration: inspirations.value?.split(",")?.filter(Boolean) || [],
      architect: architects.value?.split(",")?.filter(Boolean) || [],
      professional: professionals.value?.split(",")?.filter(Boolean) || [],
      relaxation: relaxation.value?.split(",")?.filter(Boolean) || [],
      contractor: contractors.value?.split(",")?.filter(Boolean) || [],
      project: projects.value?.split(",")?.filter(Boolean) || [],
    }),
    set: (value: {
      inspiration: string[];
      architect: string[];
      professional: string[];
      relaxation: string[];
      contractor: string[];
      project: string[];
    }) => {
      inspirations.value = value.inspiration.join(",");
      architects.value = value.architect.join(",");
      professionals.value = value.professional.join(",");
      relaxation.value = value.relaxation.join(",");
      contractors.value = value.contractor.join(",");
      projects.value = value.project.join(",");
    },
  });

  const { getFilterTags } = usePostTags();

  const { data } = useAsyncData(
    "post-filter",
    async () => await getFilterTags(),
    {
      server: false,
      default: () => [],
      watch: [],
      immediate: true,
    }
  );

  const tags = computed(() =>
    data.value.reduce((acc, tag) => {
      if (tag.filters && tag.filters.length > 0) {
        for (const filter of tag.filters) {
          if (!acc[filter]) acc[filter] = [];
          acc[filter].push(tag);
        }
      }

      return acc;
    }, {} as { [filter in PostType]: TagItem[] })
  );

  return { inspirations, architects, professionals, relaxation, filter, tags };
};
